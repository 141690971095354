import { graphql } from "gatsby";
import React from "react";

import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { Paragraph } from "@source-web/paragraph";
import { Pill } from "@source-web/pill";

import { IconDownload } from "@vfuk/source-mid-render-dark-icons";

import { Loader, ThemedSection } from "../../components";
import { AdviseNotification } from "../../components/AdviseNotification";
import PageHeading from "../../components/PageHeading";
import { PrimaryButton } from "../../components/PrimaryButton";
import {
  createAndDownloadBlobFile,
  trackDownloadClick,
  trackViewOnlineClick,
  useEventSource,
  useFetchWithTracking
} from "../../lib";
import { PageContext } from "../../types";
import {
  DowloadButton,
  FirstLine,
  IconText,
  ItemContent,
  LeftSide,
  LoadingSpinnerWrapper,
  PDFItem,
  PDFList,
  RightSide,
  SecondLine,
  ThreatBulletinContainer,
  ViewOnline
} from "./styles/threatBulletin.styles";
import { StatusNode, ThreatBulletinGenericLabelsType } from "./types";

export interface ThreatBulletinPageType {
  data: ThreatBulletinGenericLabelsType;
  pageContext: PageContext;
}

export const query = graphql`
  query GetThreatBulletinPageData($locale: String) {
    contentfulPageHeading(
      pageName: { eq: "threat bulletin" }
      node_locale: { eq: $locale }
    ) {
      heading
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingSubtext
    }
    contentfulThreatBulletinGenericLabels(node_locale: { eq: $locale }) {
      tableTitle
      buttonViewOnline
      buttonDownload
    }
    allContentfulStatuses(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          status
          key
        }
      }
    }
  }
`;

export interface IThreatBulletin {
  fileName: string;
}
export interface Ipdf {
  FileName: string;
  FileBytes: string;
}

type ThreatBulletinApiValues = {
  fileName: Array<{
    title: string;
    status: string;
    date: string;
    name: string;
    description: string;
  }>;
};

const ThreatBulletin = ({
  data,
  pageContext: { formattedLocale }
}: ThreatBulletinPageType) => {
  const {
    isLoading,
    data: threatBulletinData,
    isError
  } = useEventSource<any>(`threat-bulletins/list`, {
    isSingleEntity: false
  });

  const statusTranslations = data.allContentfulStatuses.edges.reduce<
    Record<string, string>
  >((acc, edge: StatusNode) => {
    const { key, status } = edge.node;
    acc[key] = status;
    return acc;
  }, {});

  const getStatusTranslation = (statusKey: string) => {
    return statusTranslations[statusKey] || statusKey;
  };

  const { fetchCyberhubMiddlewareWithTracking } = useFetchWithTracking();

  async function sendThreatBulletinReport({ fileName }: IThreatBulletin) {
    try {
      return await fetchCyberhubMiddlewareWithTracking(
        "threat-bulletins/report",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/octet-stream"
          },
          body: JSON.stringify({
            fileName: fileName
          })
        },
        true
      ).then((response) => {
        return response
          .blob()
          .then((blob: Blob) => blob.slice(0, blob.size, "application/pdf"))
          .then((blob: Blob) => {
            return window.URL.createObjectURL(blob);
          });
      });
    } catch (e) {
      throw new Error("Get Report failed");
    }
  }

  async function getReport(fileName: string, type: string) {
    const url = await sendThreatBulletinReport({ fileName });
    createAndDownloadBlobFile(url, fileName, type);
  }

  return (
    <>
      <PageHeading
        headingText={data.contentfulPageHeading.heading}
        headingSubtext={data.contentfulPageHeading.headingSubtext}
        backgroundImgDark={
          data.contentfulPageHeading.headingBackgroundImageDark
        }
        formattedLocale={formattedLocale}
      />

      <ThemedSection appearance="secondary">
        <ThreatBulletinContainer>
          {isLoading ? (
            <LoadingSpinnerWrapper data-testid="LoadingSpinnerWrapper">
              <Loader text={{ text: "" }} />
            </LoadingSpinnerWrapper>
          ) : (
            <>
              <Paragraph weight={3} size={2}>
                {data.contentfulThreatBulletinGenericLabels.tableTitle}
              </Paragraph>
              <PDFList>
                {threatBulletinData
                  ? threatBulletinData[0]?.fileName.map(
                      (item: ThreatBulletinApiValues["fileName"][0]) => {
                        return (
                          <PDFItem key={item.name}>
                            <LeftSide>
                              <div style={{ textAlign: "center" }}>
                                <Icon
                                  name="document-pdf"
                                  group="hifiLight"
                                  size={1}
                                />
                              </div>
                              <ItemContent>
                                <FirstLine>
                                  <Heading
                                    size={2}
                                    weight={3}
                                    noMargin
                                    id="heading-item"
                                  >
                                    {item.title}
                                  </Heading>
                                  <Pill
                                    text={getStatusTranslation(
                                      item.status.toUpperCase()
                                    )}
                                    state="success"
                                    id="pill-item"
                                  />
                                </FirstLine>
                                <SecondLine>
                                  {item.date} | {item.description}
                                </SecondLine>
                              </ItemContent>
                            </LeftSide>
                            <RightSide>
                              <ViewOnline>
                                <PrimaryButton
                                  width="auto"
                                  text={
                                    data.contentfulThreatBulletinGenericLabels
                                      .buttonViewOnline
                                  }
                                  appearance="primary"
                                  onClick={() => {
                                    getReport(item.name, "view");
                                    trackViewOnlineClick({
                                      page_locale: formattedLocale
                                    });
                                  }}
                                />
                              </ViewOnline>
                              <DowloadButton
                                onClick={() => {
                                  getReport(item.name, "download");
                                  trackDownloadClick({
                                    page_locale: formattedLocale
                                  });
                                }}
                              >
                                <IconDownload size={1} />
                                <IconText>
                                  {
                                    data.contentfulThreatBulletinGenericLabels
                                      .buttonDownload
                                  }
                                </IconText>
                              </DowloadButton>
                            </RightSide>
                          </PDFItem>
                        );
                      }
                    )
                  : null}

                {isError ? (
                  <AdviseNotification
                    state="error"
                    title="We're unable to fetch the Bulletins"
                    text="Please, try again later"
                  />
                ) : null}
              </PDFList>
            </>
          )}
        </ThreatBulletinContainer>
      </ThemedSection>
    </>
  );
};

export default ThreatBulletin;
