import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

interface StyledProps {
  theme: Theme;
  width?: number;
}

export const ThreatBulletinContainer = styled.div(
  (props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: auto;
    padding: 1.75em 1.25em;
    height: 100%;
    min-height: 55vh;
    @media (min-width: ${props.theme.breakpoints.md}px) {
      padding: 2.725em 1.25em;
    }
  `
);

export const PDFList = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `
);

export const PDFItem = styled.div`
  ${(props: StyledProps) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${props.theme.color.monochrome1.default};
    border-radius: 5px;
    padding: 20px 0;
    margin-bottom: 25px;

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      flex-direction: row;
    }
  `}
`;

export const LeftSide = styled.div(
  (props) => css`
    display: flex;
    gap: 10px;
    padding: 0 20px 0 10px;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      width: 65%;
      padding: 0 0 0 10px;
    }
  `
);

export const RightSide = styled.div(
  (props) => css`
    display: flex;
    gap: 50px;
    margin-top: 20px;
    justify-content: space-between;
    padding: 0 20px;

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      width: 30%;
      margin-top: 0;
      justify-content: end;
    }
  `
);

export const ItemContent = styled.div(() => css``);

export const FirstLine = styled.div(
  () => css`
    display: flex;
    #pill-item {
      font-size: 18px;
      font-weight: 700;
      height: 26px;
      margin-top: 5px;
    }
    #heading-item {
      margin-right: 16px;
    }
  `
);

export const SecondLine = styled.div(
  () => css`
    margin-top: 10px;
  `
);

export const ViewOnline = styled.div`
  margin-top: 10px;
`;

export const IconText = styled.div(
  () => css`
    font-size: 18px;
  `
);

export const DowloadButton = styled.div(
  () => css`
    text-align: center;
    cursor: pointer;
  `
);

export const LoadingSpinnerWrapper = styled.div(
  () => css`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);
